<template>
  <div>
    <v-row>
      <v-col lg="12">  
         <prf-lot-production
         :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100">
         ></prf-lot-production>
        </v-col>
    </v-row>
  </div>
</template>

<script>
 import PrfLotProduction from './LotProductionRegister.vue';
    
export default {
  components: {
    PrfLotProduction     
  
  },

   data() {
    return {
      parentID: 0,
      dedID:0,
     
    };
  },

  methods: {
   rowSelected(rows) {
    // this.dedID=rows[0].LinID;
  },
   
  },
};
</script>
