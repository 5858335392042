<template>
<div>
<v-dialog 
v-model=dialogNew v-if=dialogNew
persistent
>
<lot-production-new @close='dialogNew=false;$refs.crud.refresh()' ></lot-production-new>

</v-dialog>
  <s-crud ref='crud'
    @returnObject="refresh($event)"
    no-border
    title="Registro de Lotes de Producción"
    :config="this.config"
    @save="saveDed($event)"
    add
    edit
    :filter="config.filter"
    @clearForm="clearForm()"
    restore
    @rowSelected="rowSelected($event)"
    remove
  >
  <template v-slot:filter>
    <v-container>
    <v-row justify="center">

    <v-col class="mt-3">
    <v-btn color="primary" @click='dialogNew=true'> Crear Lote de Producción</v-btn>
    </v-col>
      <v-col lg="2" cols=12>
        <s-date label="Inicio" v-model=BeginDate />
      </v-col>
      <v-col lg=2 cols=12>
        <s-date label="Fin" v-model=EndDate />
      </v-col>
    </v-row>
    </v-container>
   </template>
    <template scope="props">
    
      <v-row>
        <v-col lg="12" class="s-col-form" cols="12">
          <s-text
            label="Cod. Lote de Producción"
            v-model="props.item.LotCode"
            :maxlength="15"
          ></s-text>
        </v-col>
      </v-row>
      
      <v-row>
        <v-container>
        </v-container>
      </v-row>
      
      <v-row>
        <v-col cols="12" class="s-col-form" lg="12">
            <s-toolbar
              color="info"
              dark
              add
              @add="addlotes(props.item)"
              label="Lotes de Recepcion"
            ></s-toolbar>
            <v-row>
              <v-col lg="5">
                <s-text
                  label="Guia Remisión"
                  @keyupEnter="keyEnter($event)"
                  v-model="props.item.MarNumberReferralGuide"
                ></s-text>
              </v-col>
              <v-col lg="5">
                <s-select-definition
                  label="Alerta"
                  v-model="props.item.LprAlert"
                  :def="1239"
                ></s-select-definition>
              </v-col>
              <v-col lg="2">
                <v-btn
                  mt="5"
                  block
                  color="primary"
                  @click="listar(props.item.MarNumberReferralGuide)"
                  >Buscar</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="12">
                <v-data-table
                  v-model="selectedDetalle"
                  :headers="headerDetalle"
                  :items="itemsDetalle"
                  item-key="MarID"
                  :single-select="singleSelect"
                  show-select
                  label="Edit"
                  dense
                  class="elevation-1"
                >
                </v-data-table>
              </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" class="s-col-form">
          <s-toolbar
            remove
            dark
            color="info"
            @removed="removeloteagregado()"
            label="Lotes Agregados"
          ></s-toolbar>
        </v-col>
        <v-col cols="12" class="s-col-form">
          <v-data-table
            v-model="selectedDetalleA"
            :headers="headerDetalleA"
            :items="itemsDetalleA"
            item-key="MarID"
            show-select
            label="Edit"
            dense
            class="elevation-1"
          >
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="4" cols="12">
          <s-text
            label="Total de jabas"
            number
            v-text="sumjabas"
            v-model="props.item.LprTotalJava"
          >
          </s-text>
         <!--  <s-text
            label="Total de jabas"
            v-model="props.item.LprTotalJava"
            v-text="sumjabas"
          ></s-text> -->
        </v-col>
        <v-col lg="4" cols="12">
          <s-text
            label="Total Peso Bruto"
            number
            v-text="sumgross"
            v-model="props.item.LprGrossWeight"
          ></s-text>
        </v-col>
        <v-col lg="4" cols="12">
          <s-text
            label="Total Peso Neto"
            number
            v-text="sumnet"
            v-model="props.item.LprNetWeight"
          ></s-text>
        </v-col>
        <!-- <v-col lg="4" class="s-col-form" cols="12">
          <s-text
            label="Total Peso Bruto"
            v-model="props.item.LprGrossWeight"
            v-text="sumgross"
          ></s-text>
        </v-col>
        <v-col lg="4" class="s-col-form" cols="12">
          <s-text
            label="Total Peso Neto"
            v-model="props.item.LprNetWeight"
            v-text="sumnet"
          ></s-text>
        </v-col> -->
      </v-row>

      <v-container
        style="padding-top: 0px"
        v-if="props.item != null"
        class="pb-0"
      >
        
          <v-container>
            <v-row>
              <!-- <v-col lg="3" class="s-col-form" cols="12">
                <s-text
                  label="Cod. Lote de Producción"
                  v-model="props.item.LotCode"
                  :maxlength="15"
                ></s-text>
              </v-col> -->
            </v-row>
          </v-container>

          <!-- <v-tabs centered v-model="currentItem">
                    <v-tab href="#Materiales"> Materiales</v-tab>
                     </v-tabs>-->
          <!--<v-tabs-items v-model="currentItem" style="padding: 10 10px 10px 0px">                      
                    <v-tab-item  :value="'Materiales'">
                      <v-card flat tile elevation="0"> -->
          <v-container v-if="props.item != null">
            <!-- La barra de template grilla de arriba -->
            <template>
              <v-row>
                <v-col class="mt-3">
                  <!-- <s-toolbar
                    color="info"
                    dark
                    add
                    @add="addlotes(props.item)"
                    label="Lotes de Recepcion"
                  ></s-toolbar> -->
                </v-col>
              </v-row>
            </template>
            <v-col lg="3" class="s-col-form" cols="12">
              <!-- <s-text
                label="Guia Remisión"
                @keyupEnter="keyEnter($event)"
                v-model="props.item.MarNumberReferralGuide"
              ></s-text> -->
            </v-col>
            <v-col lg="3" class="s-col-form" cols="12" v-if="false">
              <!-- <s-select-definition
                label="Alerta"
                v-model="props.item.LprAlert"
                :def="1239"
              ></s-select-definition> -->
            </v-col>
            <v-col
              ><!-- <v-btn
                color="primary"
                @click="listar(props.item.MarNumberReferralGuide)"
                >Buscar</v-btn
              > --></v-col
            >
            <!--   la grilla de arriba-->
            <!-- <v-data-table
              v-model="selectedDetalle"
              :headers="headerDetalle"
              :items="itemsDetalle"
              item-key="MarID"
              :single-select="singleSelect"
              show-select
              label="Edit"
              dense
              class="elevation-1"
            >
            </v-data-table> -->
          </v-container>
          <v-row>
            <v-row>
              <v-container v-if="props.item != null">
                <!-- La barra de template guia de abajo -->
                <template>
                  <v-row>
                    <v-col>
                      <!-- <s-toolbar
                        remove
                        color="primary"
                        @removed="removeloteagregado()"
                        label="Lotes Agregados"
                      ></s-toolbar> -->
                    </v-col>
                  </v-row>
                  <!-- La guia de abajo -->
                </template>
                <!-- <v-data-table
                  v-model="selectedDetalleA"
                  :headers="headerDetalleA"
                  :items="itemsDetalleA"
                  item-key="MarID"
                  show-select
                  label="Edit"
                  dense
                  class="elevation-1"
                >
                </v-data-table> -->
              </v-container>
            </v-row>
            <v-row>
              <!-- <v-col lg="2" class="s-col-form" cols="12">
                <s-text
                  label="Total de jabas"
                  v-model="props.item.LprTotalJava"
                  v-text="sumjabas"
                ></s-text>
              </v-col>
              <v-col lg="2" class="s-col-form" cols="12">
                <s-text
                  label="Total Peso Bruto"
                  v-model="props.item.LprGrossWeight"
                  v-text="sumgross"
                ></s-text>
              </v-col>
              <v-col lg="2" class="s-col-form" cols="12">
                <s-text
                  label="Total Peso Neto"
                  v-model="props.item.LprNetWeight"
                  v-text="sumnet"
                ></s-text>
              </v-col> -->
            </v-row>
          </v-row>

          <!--  </v-card>                     
                    </v-tab-item>
                  </v-tabs-items>-->
        
      </v-container>
    </template>
  </s-crud>
  </div>
</template>

<script>

import _sLotProduction from "@/services/FreshProduction/PrfLotProductionService.js";
import LotProductionNew  from "@/views/FreshProduction/LotProduction/LotProductionNew";
export default {
  components: {LotProductionNew },
  props: {
    LpID: 0,

    defDescription: "",
  },
  data: () => ({
    dialogNew:false,
    singleSelect: true,
    tvid: 0,
    sumjabas: 0,
    sumgross: 0,
    sumnet: 0,
    itemsDetalle: [],
    selectedDetalle: [],
    headerDetalle: [
      { text: "L.Recepción", value: "MarID" },
      // {text:"Id.Prod.", value:"PrdID"},
      { text: "Productor", value: "PrdCardName" ,width:300},
      // {text:"FundoID", value:"FagID"},

      { text: "Fundo", value: "FagName" ,width:300},
      // {text:"LoteID", value:"FltID"},
      { text: "Lote", value: "FltName" ,width:300},
      { text: "Certificaciónes", value: "TcDescription" ,width:200},
      // {text:"VrtID", value:"VrtID"},
      { text: "Variedad", value: "VrtDescription",width:100 },
      // {text:"T.CultivoID", value:"TypeCrop"},
      { text: "T.Cultivo", value: "TypeCultiveName",width:120 },

      { text: "Cultivo", value: "Cultive",width:100 },
      { text: "Jabas", value: "WbbAmountJabasWeigh" },
      { text: "Peso Bruto", value: "WwbGrossWeight" },
      { text: "Peso Neto", value: "WbbNetWeight" },
      { text: "Guia de Remision", value: "MarNumberReferralGuide" },
    ],

    ObjDetalle: {
      DetalleObj: null,
    },

    itemsDetalleA: [],
    selectedDetalleA: [],
    headerDetalleA: [
      { text: "L.Recepción", value: "MarID" },
      { text: "Id.Prod.", value: "PrdID" },
      { text: "Productor", value: "PrdCardName" },
      { text: "FundoID", value: "FagID" },

      { text: "Fundo", value: "FagName" },
      { text: "LoteID", value: "FltID" },
      { text: "Lote", value: "FltName" },
      { text: "Certificación", value: "TcDescription" },
      { text: "VrtID", value: "VrtID" },
      { text: "Variedad", value: "VrtDescription" },
      { text: "T.CultivoID", value: "TypeCrop" },
      { text: "T.Cultivo", value: "TypeCultiveName" },

      { text: "Cultivo", value: "Cultive" },
      { text: "Jabas", value: "WbbAmountJabasWeigh" },
      { text: "Peso Bruto", value: "WwbGrossWeight" },
      { text: "Peso Neto", value: "WbbNetWeight" },
      { text: "Guia de Remision", value: "LpdNumberReferralGuide" },
    ],

    ObjDetalleA: {
      DetalleObjA: null,
    },

    dedValue: 0,
    config: {
      title: "LotProductionRegister",
      service: _sLotProduction,
      filter: {
        Indicador: 0,
        Dato: 0,
       
      },
      model: {
         SecCreate:'date',
        LprID: "ID",
        LprStatus: "int",
        SecStatus: "int",
        LprNetWeight: 0,
        LprGrossWeight: 0,
        LprTotalJava: 0,
      },
      headers: [  
        {
          text: "ID",
          value: "LprID",
          align: "end",
            width:60
        },
         {
          text: "Estado",
          value: "LprStatus",
          align: "center",
            width:100
        },
        {
          text: "Fecha",
          value: "SecCreate",
          align: "end",
          width:100
        },
        {
          text: "Lote",
          value: "FltName",
          sortable: false,
            width:300
        },
        {
          text: "Certificación",
          value: "TcDescription",
          sortable: false,
            width:200
        },
        {
          text: "Total Jabas",
          value: "LprTotalJava",
          align: "end",
            width:100
        },

        {
          text: "Cultivo",
          value: "DedDescription",
          align: "center",
           width:100
        },
        {
          text: "Variedad",
          value: "VrtDescription",
          align: "end",
            width:100
        },

        {
          text: "Guia Remisión",
          value: "MarNumberReferralGuide",
          align: "end",
           width:120
        },
        {
          text: "Guia Cosecha",
          value: "MarNumberHarvestGuide",
          align: "end",
        width:120
        },
        {
          text: "Prog. Cosecha N°",
          value: "HvsID",
          align: "end",
            width:150
        },
     ],

     
    },
  }),

  computed: {
    filter() {
      return {};
    },
  },
  methods: {
    removeloteagregado() {
      this.selectedDetalleA.forEach((element) => {
        this.itemsDetalleA = this.itemsDetalleA.filter(
          (x) => x.MarID != element.MarID
        );
      });
      this.sumar();
    },

    sumar() {
      this.sumjabas = 0;
      this.sumgross = 0;
      this.sumnet = 0;
      this.itemsDetalleA.forEach((element) => {
        this.sumjabas = this.sumjabas + parseInt(element.WbbAmountJabasWeigh);
        this.sumgross = this.sumgross + parseInt(element.WwbGrossWeight);
        this.sumnet = this.sumnet + parseInt(element.WbbNetWeight);
      });
    },
    rowSelected(items) {
      this.LpID = 0;
      this.itemsDetalleA = [];
      if (items.length > 0) {
        this.LpID = items[0].LprID;
        this.$emit("rowSelected", items);
        this.listarDetalle();
      }
      this.sumar();
    },
    addlotes() {
      let objM = {
        TcDescription: this.selectedDetalle[0].TcDescription,
        TcID: this.selectedDetalle[0].TcID,
        MarID: this.selectedDetalle[0].MarID,
        PrdID: this.selectedDetalle[0].PrdID,
        PrdCardName: this.selectedDetalle[0].PrdCardName,
        FagID: this.selectedDetalle[0].FagID,
        FagName: this.selectedDetalle[0].FagName,
        FltID: this.selectedDetalle[0].FltID,
        FltName: this.selectedDetalle[0].FltName,
        VrtID: this.selectedDetalle[0].VrtID,
        VrtDescription: this.selectedDetalle[0].VrtDescription,
        TypeCrop: this.selectedDetalle[0].TypeCrop,
        TypeCultiveName: this.selectedDetalle[0].TypeCultiveName,
        Cultive: this.selectedDetalle[0].Cultive,
        WbbAmountJabasWeigh: this.selectedDetalle[0].WbbAmountJabasWeigh,
        WwbGrossWeight: this.selectedDetalle[0].WwbGrossWeight,
        WbbNetWeight: this.selectedDetalle[0].WbbNetWeight,
        LpdNumberReferralGuide: this.selectedDetalle[0].MarNumberReferralGuide,
        LprID: this.LpID > 0 ? this.LpID : 0,
      };
      let exist = false;
      this.itemsDetalleA.forEach(element => {
        if(this.selectedDetalle[0].MarID == element.MarID || this.selectedDetalle[0].VrtID != element.VrtID
        || this.selectedDetalle[0].TcID != element.TcID || this.selectedDetalle[0].MarNumberReferralGuide != element.LpdNumberReferralGuide) 
        {
          exist = true;
        }
      });
      if (exist == false) {
        if (this.tvid > 0) {
          objM.MarID = this.tvid;
        }
        this.itemsDetalleA.push(objM);
        console.log(this.itemsDetalleA);
        this.sumar();
      }
    },

    listarDetalle() {
      _sLotProduction
        .listDetailot(this.LpID, this.$fun.getUserID())
        .then((response) => {
          let data = response.data;
          this.itemsDetalleA = [];
          data.forEach((element) => {
            let obj = {
              TcDescription: element.TcDescription,
              TcID: element.TcID,
              MarID: element.MarID,
              PrdID: element.PrdID,
              PrdCardName: element.PrdCardName,
              FagID: element.FagID,
              FagName: element.FagName,
              FltID: element.FltID,
              FltName: element.FltName,
              VrtID: element.VrtID,
              VrtDescription: element.VrtDescription,
              TypeCrop: element.TypeCrop,
              TypeCultiveName: element.TypeCultiveName,
              Cultive: element.Cultive,
              WbbAmountJabasWeigh: element.WbbAmountJabasWeigh,
              WwbGrossWeight: element.WwbGrossWeight,
              WbbNetWeight: element.WbbNetWeight,
              LpdNumberReferralGuide: element.MarNumberReferralGuide,
              LprID: element.LprID,
            };
            this.itemsDetalleA.push(obj);
            this.sumar();
          });
        })
        .catch((err) => {
          console.error("err");
        });
    },

    listar(guide) {
      this.tvid = 0;
      //if (items.length > 0) this.tvid = items[0].TvID;
      //Llenar materiales para editado
      this.itemsDetalle = [];
      _sLotProduction
        .listDetail(guide, this.$fun.getUserID())
        .then((response) => {
          let data = response.data;
          data.forEach((element) => {
            let obj = {
              TcDescription: element.TcDescription,
              TcID: element.TcID,
              MarID: element.MarID,
              PrdID: element.PrdID,
              PrdCardName: element.PrdCardName,
              FagID: element.FagID,
              FagName: element.FagName,
              FltID: element.FltID,
              FltName: element.FltName,
              VrtID: element.VrtID,
              VrtDescription: element.VrtDescription,
              TypeCrop: element.TypeCrop,
              TypeCultiveName: element.TypeCultiveName,
              Cultive: element.Cultive,
              WbbAmountJabasWeigh: element.WbbAmountJabasWeigh,
              WwbGrossWeight: element.WwbGrossWeight,
              WbbNetWeight: element.WbbNetWeight,
              MarNumberReferralGuide: element.MarNumberReferralGuide,
            };
            this.itemsDetalle.push(obj);
          });
        })
        .catch((err) => {
          console.error("err");
        });
    },

    refresh(item) {
      this.$emit("refresh", item);
    },
    clearForm() {
      this.$refs.LinDescription.focus();
    },
    saveDed(item) {
      item.LprID = item.LprID > 0 ? item.LprID : 0;
      item.SecStatus = 1;
      item.LprStatus = 1;
      item.LprTotalJava = this.sumjabas;
      item.LprGrossWeight = this.sumgross;
      item.LprNetWeight = this.sumnet;
      item.DetailsLotProduction = this.itemsDetalleA;
      if (this.sumjabas == 0 || this.sumgross == 0 || this.WbbNetWeight == 0) {
        this.$fun.alert("Seleccione al menos un lote de producción", "warning");
        return;
      }
      /* if (item.alert== 0) {
        this.$fun.alert("ingrese una alerta", "warning");
        return;
      }*/

      item.save();
      this.itemsDetalleA = [];
      this.itemsDetalle = [];
    },
  },
  watch: {
    /*itemsDetalleA(){   
        this.sumar();
     
      },*/
  },
};
</script>
