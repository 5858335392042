import Service from "../Service";
import Vue from "vue";
const resource = "lotProduction/"

export default {
    save(prfLotPr, requestID) {
      console.log(prfLotPr);
         return Service.post(resource + "save", prfLotPr, {
          params: { requestID: requestID },
        });
      },
     
  pagination(parameters, requestID) {
      return Service.post(resource + "pagination", parameters,{
        params: { requestID: requestID  }
    });
  }, 
  
  listboardingSchedule(RequestID){    
    return Service.post(resource + "listboardingSchedule",{},{
    params:{RequestID: RequestID}
  });  
  },

  GetUrllistboardingSchedule(){
  return Vue.prototype.$http.defaults.baseURL + "/lotProduction/listboardingSchedule";
  },

  listDetail(marNumberReferralGuide,RequestID){ 
    return Service.post(resource + "listDetail",{},{
    params:{MarNumberReferralGuide: marNumberReferralGuide,RequestID: RequestID}
  });  
  },

  listDetailot(lote,RequestID){ 
    return Service.post(resource + "listDetailot",{},{
    params:{Lote: lote,RequestID: RequestID}
  });  
  },
  GetUrllistDetail(){
  return Vue.prototype.$http.defaults.baseURL + "/lotProduction/listDetail";
  },

};