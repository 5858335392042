<template>
<v-col class="pt-0 pr-0 pl-0">
<v-row>
<v-col class="m-0 p-0">
 <v-row>
 <v-col>
   <s-toolbar @save=save() @close='$emit("close")' label="Crear Lote de Producción" save color="primary" dark close></s-toolbar></v-col>
 </v-row>   
        <v-card>
          <v-row>
            <v-col class="pb-0 pl-6 pr-6 ">
            <h3>Lotes de Recepción</h3>
            <v-divider></v-divider>
              <v-data-table class="pt-1"
                :items="itemsLotReception"
                item-key="MarID"
                show-select dense
                @item-selected=selectedReception($event)
                v-model=itemsLotPro
                :headers=headerDetail
              >
              </v-data-table>
            </v-col>
          </v-row>
           <v-row>
            <v-col class="pt-0 pl-6 pr-6 ">
            <h3>Lote Producción</h3>
            <v-divider></v-divider>
              <v-data-table class="pt-1"
                :items="itemsLotPro" dense
                item-key="MarID"
                show-select
                hidden-footer
                  @item-selected=selectedProduction($event)
                v-model=itemsLotPro
                :headers=headerDetail
              >
              </v-data-table>
              <v-row class="pr-6 pt-6">         
             <v-spacer></v-spacer>
             <h3> Total Jabas: </h3> 
             <h3> {{totalJabas}}</h3>     
              </v-row>
                 <v-row class="pr-6 ">         
             <v-spacer></v-spacer>
            <h3>Peso Neto:  {{totalNetWeight}}</h3>     
              </v-row>
                 <v-row class="pr-6 ">         
             <v-spacer></v-spacer>
            <h3>Peso Bruto:  {{totalGrossWeight}}</h3>      
              </v-row>
            </v-col>
          </v-row>
        </v-card>
     </v-col>
     </v-row>
     </v-col>
</template>
<script>
import _sLotReception from "@/services/FreshProduction/MaterialReceptionService.js";
import _sLotProduction from "@/services/FreshProduction/PrfLotProductionService.js";
export default {
  components: {},
  data: () => ({
      totalJabas:0,
      totalGrossWeight:0,
      totalNetWeight:0,
itemsLotReception:[],
          headerDetail: [
      { text: "L.R.", value: "MarID",width:40 },
      { text: "Productor", value: "PrdCardName" ,width:200},
      { text: "Lote", value: "FltName" ,width:200},
      { text: "Certificaciónes", value: "TcDescription" ,width:200},
      { text: "Variedad", value: "VrtDescription",width:70 },
      { text: "T.Cultivo", value: "TypeCropName",width:120 },
      { text: "Cultivo", value: "TypeCultiveName",width:80 },
      { text: "Jabas", value: "AmountJabasWeigh" },
            { text: "Peso Neto", value: "WbbNetWeight" },
             { text: "Peso Bruto", value: "WbbGrossWeight" },
      { text: "Guia Remision", value: "MarNumberReferralGuide" },
         { text: "Guia Cosecha", value: "MarNumberHarvestGuide" },
    ],
    itemsLotPro:[],
  }),
  created() {
      _sLotReception.byCreateLotProduction({}, this.$fun.getUserID()).then((r)=>{
this.itemsLotReception=r.data;
      })
  },
  methods: {
      save(){
if (this.itemsLotPro.length<=0) {
        this.$fun.alert("Seleccione al menos un lote de Recepción", "warning");
        return;
      }
let item={};
item.LprAlert=1;
item.UsrCreateID=this.$fun.getUserID();
this.itemsLotPro.map(e=>{
    e.UsrCreateID=this.$fun.UsrCreateID
})
      item.SecStatus = 1;
      item.LprStatus = 1;
      item.LprTotalJava = this.totalJabas;
      item.LprGrossWeight = this.totalGrossWeight;
      item.LprNetWeight = this.totalNetWeight;
      item.DetailsLotProduction = this.itemsLotPro;
this.$fun.alert('Seguro de Crear?','question').then((r)=>{
if(r.value){
   _sLotProduction.save(item,this.$fun.getUserID()).then(r=>{
          this.$fun.alert('Guardado Corectamente','success');
    this.$emit('close');
      });
}
})
      },
      selectedReception(obj){
if(obj.value){
//sumas
if(this.itemsLotPro.length>0){
    //VALIDA QUE TENGA LA MISMA VARIEDAD, CULTIVO . TIPO DE CULTIVO Y GUIA DE REMISION
    if(!(this.itemsLotPro[0].TypeCropName==obj.item.TypeCropName&&
    this.itemsLotPro[0].TypeCultiveName==obj.item.TypeCultiveName&&
    this.itemsLotPro[0].VrtDescription==obj.item.VrtDescription&&
    this.itemsLotPro[0].MarNumberReferralGuide==obj.item.MarNumberReferralGuide)){
        this.$fun.alert('Los Lotes Tienes que coincidir con Variedad, Cultivo y Guía de Remisión','warning');
        this.itemsLotPro=this.itemsLotPro.filter(x=>x.MarID!=obj.item.MarID);
        return;
    }
}
this.totalJabas+=obj.item.AmountJabasWeigh;
this.totalGrossWeight+=obj.item.WbbNetWeight;
this.totalNetWeight+= obj.item.WbbGrossWeight;
}else{
    //restas
    this.totalJabas-=obj.item.AmountJabasWeigh;
this.totalGrossWeight-=obj.item.WbbNetWeight;
this.totalNetWeight-=obj.item.WbbGrossWeight;
}
      },
       selectedProduction(obj){
          if(obj.value){
//restas
  this.totalJabas-=obj.item.AmountJabasWeigh;
this.totalGrossWeight-=obj.item.WbbNetWeight;
this.totalNetWeight-=obj.item.WbbGrossWeight;
          }
      }
  },
  computed: {},
};
</script>
